import React, { useEffect, useRef } from "react";
import { SutterLoader } from '../components'
import { loadModules } from "esri-loader";
import _ from "lodash";
import $ from "jquery";

var layerurl =
  "https://mapservice.gov.in/gismapservice/rest/services/BharatMapService/Admin_Boundary_District/MapServer";
const token =
  process.env.NODE_ENV === "development"
    ? "?Token=Dnm3hRBUqThkJNc8HiAjJ9Y2eraifDPo4bMDE3IwMwPYw5mjvDivWu1Q1fqNegpg"
    : "?Token=HnAJpcFVIKDiCsPze4RNmbyujiqaUyJUf_sW4zZXKsRKewm4YO9w5iWZSKbypnKe0qrbz-m6VcqI3jJzvDxSqPuXjZcibfi00C1tBR1o0GQ.";

const distLayerUrl =
  "https://mapservice.gov.in/gismapservice/rest/services/BharatMapService/District_Boundary/MapServer";
const distLayerToken =
  process.env.NODE_ENV === "development"
    ? "?Token=7uZyoAPA6UTko1ttNM4Yw2sdEHBR_qNBCjHwh_eBMr-RorOOEfBG0f0BulX_KlfO"
    : "?Token=kdRpMSOcuaaJQdFWLizStlR-O2_CrfFGLMA6Ycc55igAuG2QHNE1RZ2S49CEPNbtK_BOOAv1BDekCZeVKyB2PUX7W-h8pmaZk0G-ZrekcnY.";

    const PCandACLayerUrl =
    "https://mapservice.gov.in/gismapservice/rest/services/BharatMapService/AC_PC/MapServer";
    const PCandACLayerToken =
  process.env.NODE_ENV === "development"
    ? "?Token=OdWaq4fzflB2D15RX31S2E0d3x9QhLZKaWxiZimJ9tI9quW4B48wlr1BCoAs7g5W"
    : "?Token=ssXJTnShguYsRuzYj1s4m4s5FIJ8G0TI1mbylZqfOBy5bxJ9JA_TapweWAbU6SWHP3DeH1brwjlJ54CVl-fwhg..";

// console.log(process.env.NODE_ENV,process.env.NODE_ENV === 'development')

const stateWiseDataFilterByReportType = (
  reportName,
  group = { code: "StateCode", name: "StateName" }
) => {
  const dataList = _.get(window, "reportDataState.dataList", []);
  const selectedReport = _.find(
    _.get(window, "reportDataState.masterList", []),
    (fnd) => fnd.reportType === reportName
  );

  const groupCode = _.get(group, "code");
  const groupName = _.get(group, "name");
  const data = _.get(window, "dataListNew", []);
  const groupData = _.orderBy(
    _.groupBy(data, groupCode),
    (o) => _.get(o, `0.${groupName}`),
    "asc"
  );

  const dataListNew = _.map(groupData, (obj) => {
    const demand = _.sumBy(obj, (s) => _.get(s, "demand", 0) || 0);
    const committed = _.sumBy(obj, (s) => _.get(s, "committed", 0) || 0);
    const completed = _.sumBy(obj, (s) => _.get(s, "completed", 0) || 0);
    const grounded = _.sumBy(obj, (s) => _.get(s, "grounded", 0) || 0);
    const released = _.sumBy(obj, (s) => _.get(s, "released", 0) || 0);
    const sanctioned = _.sumBy(obj, (s) => _.get(s, "sanctioned", 0) || 0);
    const stateGrant = _.sumBy(obj, (s) => _.get(s, "stateGrant", 0) || 0);
    const overall = _.sumBy(obj, (s) => _.get(s, "ProjectCost", 0) || 0);
    const expenditure = _.sumBy(obj, (s) => _.get(s, "expenditure", 0) || 0);

    return {
      StateCode: _.get(obj, "0.StateCode"),
      DistrictCode: _.get(obj, "0.DistrictCode"),
      CityCode: _.get(obj, "0.CityCode"),
      name: _.get(obj, `0.${groupName}`),
      value: _.get(obj, `0.${groupCode}`),
      demand,
      committed,
      completed,
      grounded,
      released,
      sanctioned,
      stateGrant,
      overall,
      expenditure,
    };
  });

  const demandSaturationScore = _.map(dataListNew, (obj) => {
    const reportType = _.get(selectedReport, "reportType");
    // console.log('reportType----->>?', reportType)
    if (reportType === "demandSaturation") {
      const score =
        Math.round(
          (Number(_.get(obj, "sanctioned") || 0) /
            Number(_.get(obj, "demand") || 0)) *
          100
        ) > 100
          ? 100
          : Math.round(
            (Number(_.get(obj, "sanctioned") || 0) /
              Number(_.get(obj, "demand") || 0)) *
            100
          );
      const finalScore = !score ? 0 : score;
      const colorSet = _.find(
        _.get(selectedReport, "childList"),
        (fnd) =>
          _.get(fnd, "fromValue") <= finalScore &&
          _.get(fnd, "toValue") >= finalScore
      );
      return {
        score: finalScore,
        color: _.get(colorSet, "color"),
        stateCode: _.get(obj, "stateCode"),
        ...obj,
      };
    } else if (reportType === "constructionVSsanctioned") {
      const score =
        Math.round(
          (Number(_.get(obj, "grounded") || 0) /
            Number(_.get(obj, "sanctioned") || 0)) *
          100
        ) > 100
          ? 100
          : Math.round(
            (Number(_.get(obj, "grounded") || 0) /
              Number(_.get(obj, "sanctioned") || 0)) *
            100
          );
      const finalScore = !score ? 0 : score;
      const colorSet = _.find(
        _.get(selectedReport, "childList"),
        (fnd) =>
          _.get(fnd, "fromValue") <= finalScore &&
          _.get(fnd, "toValue") >= finalScore
      );
      return {
        score: finalScore,
        color: _.get(colorSet, "color"),
        stateCode: _.get(obj, "stateCode"),
        ...obj,
      };
    } else if (reportType === "completionVSsanctioned") {
      const score =
        Math.round(
          (Number(_.get(obj, "completed") || 0) /
            Number(_.get(obj, "sanctioned") || 0)) *
          100
        ) > 100
          ? 100
          : Math.round(
            (Number(_.get(obj, "completed") || 0) /
              Number(_.get(obj, "sanctioned") || 0)) *
            100
          );
      const finalScore = !score ? 0 : score;
      const colorSet = _.find(
        _.get(selectedReport, "childList"),
        (fnd) =>
          _.get(fnd, "fromValue") <= finalScore &&
          _.get(fnd, "toValue") >= finalScore
      );
      return {
        score: finalScore,
        color: _.get(colorSet, "color"),
        stateCode: _.get(obj, "stateCode"),
        ...obj,
      };
    } else if (reportType === "investmentMobilisation") {
      const score =
        Math.round(
          (Number(_.get(obj, "stateGrant") || 0) /
            Number(_.get(obj, "overall") || 0)) *
          100
        ) > 100
          ? 100
          : Math.round(
            (Number(_.get(obj, "stateGrant") || 0) /
              Number(_.get(obj, "overall") || 0)) *
            100
          );
      const finalScore = !score ? 0 : score;
      const colorSet = _.find(
        _.get(selectedReport, "childList"),
        (fnd) =>
          _.get(fnd, "fromValue") <= finalScore &&
          _.get(fnd, "toValue") >= finalScore
      );
      return {
        score: finalScore,
        color: _.get(colorSet, "color"),
        stateCode: _.get(obj, "stateCode"),
        ...obj,
      };
    } else if (reportType === "releaseCentralAssistance") {
      const score =
        Math.round(
          (Number(_.get(obj, "released") || 0) /
            Number(_.get(obj, "committed") || 0)) *
          100
        ) > 100
          ? 100
          : Math.round(
            (Number(_.get(obj, "released") || 0) /
              Number(_.get(obj, "committed") || 0)) *
            100
          );
      const finalScore = !score ? 0 : score;
      const colorSet = _.find(
        _.get(selectedReport, "childList"),
        (fnd) =>
          _.get(fnd, "fromValue") <= finalScore &&
          _.get(fnd, "toValue") >= finalScore
      );
      return {
        score: finalScore,
        color: _.get(colorSet, "color"),
        stateCode: _.get(obj, "stateCode"),
        ...obj,
      };
    } else if (reportType === "fundUtilisation") {
      const score =
        Math.round(
          (Number(_.get(obj, "expenditure") || 0) /
            Number(_.get(obj, "released") || 0)) *
          100
        ) > 100
          ? 100
          : Math.round(
            (Number(_.get(obj, "expenditure") || 0) /
              Number(_.get(obj, "released") || 0)) *
            100
          );
      const finalScore = !score ? 0 : score;
      const colorSet = _.find(
        _.get(selectedReport, "childList"),
        (fnd) =>
          _.get(fnd, "fromValue") <= finalScore &&
          _.get(fnd, "toValue") >= finalScore
      );
      return {
        score: finalScore,
        color: _.get(colorSet, "color"),
        stateCode: _.get(obj, "stateCode"),
        ...obj,
      };
    }

    return {};
  });
  // console.log('demandSaturationScore--------->',demandSaturationScore)
  return demandSaturationScore;
};

// Report Table Template

const renderReportTable = (demandSaturationScore, reportName, reportLabel) => {
  // console.log('demandSaturationScore------>',demandSaturationScore)
  $("#reportInTable").empty();
  // const selectedReport = _.find(_.get(window, 'reportDataState.masterList', []), fnd => fnd.title === reportName)

  const statesList = _.get(window, "reportDataState.statesList", []);

  const dataList = _.get(window, "reportDataState.dataList", []);
  let template;

  if (reportName === "demandSaturation") {
    template = `<table class='bg-c-white customTable dataTable' style='font-size: 12px'>
  <thead class='text-white text-center' style='background:#005a99'>
    <tr style='text-align:center'>
      <th class="center" style='width: 30px;max-width: 30px; min-width: 30px'>Sr.</th>
      <th class="center"> Name of the <br />${reportLabel === 'state' ? 'State/UT' : reportLabel === 'parliamentConstituency' ? 'Parliament Constituency' : reportLabel === 'assemblyConstituency' ? 'Assembly Constituency' :'Districts'}</th>
      <th>Demand</th>
      <th>Houses<br /> Sanctioned</th>
    </tr>
  </thead>
  <tbody>
    ${_.map(demandSaturationScore, (obj, idx) => {
      // console.log('stateData-------->>',stateData)
      return `<tr key=${idx} style='background: ${_.get(obj, "color")}'>
          <td style='text-align:center;'>${idx + 1}.</td>
          <td>${_.get(obj, "name", "")}</td>
          <td class='text-right'>${Number(_.get(obj, "demand"))
          ? Number(_.get(obj, "demand")).toLocaleString("en-in")
          : "-"
        }</td>
          <td class='text-right'>${Number(_.get(obj, "sanctioned"))
          ? Number(_.get(obj, "sanctioned")).toLocaleString("en-in")
          : "-"
        }</td>
        </tr>`;
    }).join("")}
  </tbody>
</table>`;
  } else if (
    reportName === "constructionVSsanctioned" ||
    reportName === "completionVSsanctioned"
  ) {
    template = `<table class='bg-c-white customTable dataTable' style='font-size: 12px'>
      <thead class='label label-warning text-white text-center' style='background:#005a99'>
        <tr style='text-align: center'>
          <th class="center" style='width: 30px; max-width: 30px; min-width: 30px;'>Sr.</th>
          <th class="center"> Name of the <br />${reportLabel === 'state' ? 'State/UT' : reportLabel === 'parliamentConstituency' ? 'Parliament Constituency' : reportLabel === 'assemblyConstituency' ? 'Assembly Constituency' : 'Districts'}</th>
          <th>Houses<br /> Sanctioned</th>
          <th>Houses<br /> Grounded*</th>
          <th>Houses<br /> Completed*</th>
        </tr>
      </thead>
      <tbody>
        ${_.map(demandSaturationScore, (obj, idx) => {
      return `<tr key=${idx} style='background: ${_.get(obj, "color")}'>
              <td style='width: 30px; max-width: 30px; text-align:center;'>${idx + 1
        }.</td>
              <td>${_.get(obj, "name", "")}</td>
              <td class='text-right'>${Number(_.get(obj, "sanctioned"))
          ? Number(_.get(obj, "sanctioned")).toLocaleString("en-in")
          : "-"
        }</td>
              <td class='text-right'>${Number(_.get(obj, "grounded"))
          ? Number(_.get(obj, "grounded")).toLocaleString("en-in")
          : "-"
        }</td>
              <td class='text-right'>${Number(_.get(obj, "completed"))
          ? Number(_.get(obj, "completed")).toLocaleString("en-in")
          : "-"
        }</td>
            </tr>`;
    }).join("")}
      </tbody>
    </table>`;
  } else if (reportName === "investmentMobilisation") {
    template = `<table class='bg-c-white customTable dataTable' style='font-size: 12px'>
      <thead class='label label-warning text-white text-center' style='background: #005a99'>
        <tr style='text-align: center'>
          <th class="center" style='width: 30px; max-width: 30px; min-width: 30px'>Sr.</th>
          <th class="center"> Name of the <br />${reportLabel === 'state' ? 'State/UT' : reportLabel === 'parliamentConstituency' ? 'Parliament Constituency' : reportLabel === 'assemblyConstituency' ? 'Assembly Constituency' : 'Districts'}</th>
          <th>Total Investment <br />  <small style='color:orange'>(in Cr.)</small></th>
          <th>Investment By<br /> ${reportLabel === 'state' ? 'State/UT' : 'Districts'} <br />  <small style='color: orange'>(in Cr.)</small></th>
        </tr>
      </thead>
      <tbody>
        ${_.map(demandSaturationScore, (obj, idx) => {
      return `<tr key=${idx} style='background: ${_.get(obj, "color")}'>
                <td style='width: 30, max-width: 30px; text-align:center'>${idx + 1
        }.</td>
                <td>${_.get(obj, "name", "")}</td>
                <td class='text-right'>${Number(_.get(obj, "overall"))
          ? Number(_.get(obj, "overall")).toLocaleString("en-in")
          : "-"
        }</td>
                <td class='text-right'>${Number(_.get(obj, "overall") || 0) -
          Number(_.get(obj, "committed") || 0)
          ? (
            Number(_.get(obj, "overall") || 0) -
            Number(_.get(obj, "committed") || 0)
          ).toLocaleString("en-in")
          : "-"
        }</td>
              </tr>`;
    }).join("")}
      </tbody>
    </table>`;
  } else if (reportName === "releaseCentralAssistance") {
    template = `<table class='bg-c-white customTable dataTable' style='font-size: 12px'>
      <thead class='label label-warning text-white text-center' style='background: #005a99'>
        <tr style='text-align:center'>
          <th class="center" style='width: 30px;max-width: 30px; min-width: 30px'>Sr.</th>
          <th class="center"> Name of the <br />${reportLabel === 'state' ? 'State/UT' : reportLabel === 'parliamentConstituency' ? 'Parliament Constituency' : reportLabel === 'assemblyConstituency' ? 'Assembly Constituency' : 'Districts'}</th>
          <th>Sanctioned <br />  <small style='color:orange'>(in Cr.)</small></th>
          <th>Released <br />  <small style='color:orange'>(in Cr.)</small></th>
        </tr>
      </thead>
      <tbody>
          ${_.map(demandSaturationScore, (obj, idx) => {
      return `<tr key=${idx} style='background: ${_.get(obj, "color")}'>
                    <td style='width: 30; max-width: 30px; text-align:center;'>${idx + 1
        }.</td>
                    <td>${_.get(obj, "name", "")}</td>
                    <td class='text-right'>${Number(_.get(obj, "committed"))
          ? Number(_.get(obj, "committed")).toLocaleString(
            "en-in"
          )
          : "-"
        }</td>
                    <td class='text-right'>${Number(_.get(obj, "released") || 0)
          ? Number(_.get(obj, "released") || 0).toLocaleString(
            "en-in"
          )
          : "-"
        }</td>
                 </tr>`;
    }).join("")}
      </tbody>
    </table>`;
  } else if (reportName === "fundUtilisation") {
    template = `<table class='bg-c-white customTable dataTable' style='font-size: 12'>
      <thead class='label label-warning text-white text-center' style='background: #005a99'>
        <tr style='text-align: center'>
          <th class="center" style='width: 30px; max-width: 30px; min-width: 30px'>Sr.</th>
          <th class="center"> Name of the <br />${reportLabel === 'state' ? 'State/UT' : reportLabel === 'parliamentConstituency' ? 'Parliament Constituency' : reportLabel === 'assemblyConstituency' ? 'Assembly Constituency'  : 'Districts'}</th>
          <th>Released <br />  <small style='color: orange'>( Rs in Cr.)</small></th>
          <th>Expenditure <br />  <small style='color: orange'>(Rs in Cr.)</small></th>
        </tr>
      </thead>
      <tbody>
        
                ${_.map(demandSaturationScore, (obj, idx) => {
      return `<tr key=${idx} style='background: ${_.get(
        obj,
        "color"
      )}'>
                        <td style='width: 30px; max-width: 30px;text-align:center'>${idx + 1
        }.</td>
                        <td>${_.get(obj, "name", "")}</td>
                        <td class='text-right'>${Number(_.get(obj, "released"))
          ? Number(_.get(obj, "released")).toLocaleString(
            "en-in"
          )
          : "-"
        }</td>
                        <td class='text-right'>${Number(_.get(obj, "expenditure") || 0)
          ? Number(
            _.get(obj, "expenditure") || 0
          ).toLocaleString("en-in")
          : "-"
        }</td>
                      </tr>`;
    }).join("")}
               
      </tbody>
    </table>`;
  }

  $("#reportInTable").append(template);
};

const Maps = ({ props }) => {
  const mapElement = useRef(null);
  useEffect(() => {
    let map;
    loadModules(
      [
        "esri/map",
        "esri/SpatialReference",
        "esri/dijit/BasemapGallery",
        "esri/dijit/BasemapLayer",
        "esri/dijit/Basemap",
        "esri/layers/ArcGISDynamicMapServiceLayer",
        "esri/layers/ImageParameters",
        "esri/layers/ArcGISTiledMapServiceLayer",
        "esri/layers/FeatureLayer",
        "esri/geometry/webMercatorUtils",
        "esri/layers/GraphicsLayer",
        "esri/geometry/Point",
        "esri/geometry/Circle",
        "esri/units",
        "dojo/_base/event",
        "esri/geometry/geometryEngine",
        "esri/tasks/query",
        "esri/tasks/LegendLayer",
        "esri/tasks/QueryTask",
        "esri/lang",
        "esri/geometry/Extent",
        "dojo/dom-construct",
        "dojo/dom-style",
        "dojo/mouse",
        "dojo/dom-class",
        "dojo/dom",
        "esri/dijit/Scalebar",
        "esri/dijit/HomeButton",
        "esri/symbols/SimpleMarkerSymbol",
        "esri/symbols/SimpleLineSymbol",
        "esri/symbols/SimpleFillSymbol",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/SimpleRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/symbols/Font",
        "esri/symbols/TextSymbol",
        "esri/Color",
        "esri/graphic",
        "esri/dijit/Search",
        "esri/InfoTemplate",
        "esri/dijit/InfoWindow",
        "esri/dijit/Print",
        "esri/tasks/PrintTemplate",
        "esri/config",
        "esri/request",
        "dojo/_base/array",
        "dojo/_base/connect",
        "dojox/grid/DataGrid",
        "dojo/data/ItemFileWriteStore",
        "dojo/data/ItemFileReadStore",
        "dijit/Dialog",
        "dijit/ColorPalette",
        "dojo/_base/lang",
        "esri/toolbars/navigation",
        "esri/dijit/Legend",
        "esri/dijit/Measurement",
        "esri/dijit/LayerList",
        "dojo/i18n!esri/nls/jsapi",
        "dojo/on",
        "esri/urlUtils",
        "dojo/parser",
        "dijit/registry",
        "dijit/TitlePane",
        "dijit/layout/ContentPane",
        "esri/dijit/LayerSwipe",
        "dojox/charting/Chart",
        "dojox/charting/action2d/Highlight",
        "dojox/charting/action2d/Tooltip",
        "dojox/charting/plot2d/Columns",
        "dojox/charting/plot2d/Bars",
        "dojox/charting/axis2d/Default",
        "dijit/Toolbar",
        "dijit/form/Select",
        "dijit/layout/ContentPane",
        "dijit/layout/TabContainer",
        "dijit/layout/BorderContainer",
        "dijit/form/ComboBox",
        "dojo/domReady!",
      ],
      {
        css: true,
        version: "3.38",
        // version:'3.25'
      }
    ).then(
      ([
        Map,
        SpatialReference,
        BasemapGallery,
        BasemapLayer,
        Basemap,
        ArcGISDynamicMapServiceLayer,
        ImageParameters,
        ArcGISTiledMapServiceLayer,
        FeatureLayer,
        webMercatorUtils,
        GraphicsLayer,
        Point,
        Circle,
        units,
        event,
        geometryEngine,
        Query,
        LegendLayer,
        QueryTask,
        esriLang,
        Extent,
        domConstruct,
        domstyle,
        mouse,
        domClass,
        dom,
        Scalebar,
        HomeButton,
        SimpleMarkerSymbol,
        SimpleLineSymbol,
        SimpleFillSymbol,
        PictureMarkerSymbol,
        UniqueValueRenderer,
        SimpleRenderer,
        ClassBreaksRenderer,
        Font,
        TextSymbol,
        Color,
        Graphic,
        Search,
        InfoTemplate,
        InfoWindow,
        Print,
        PrintTemplate,
        esriConfig,
        esriRequest,
        arrayUtils,
        connect,
        DataGrid,
        ItemFileWriteStore,
        ItemFileReadStore,
        Dialog,
        ColorPalette,
        lang,
        Navigation,
        Legend,
        Measurement,
        LayerList,
        esriBundle,
        on,
        urlUtils,
        parser,
        registry,
        TitlePane,
        ContentPane,
        LayerSwipe,
        Chart,
        Highlight,
        Tooltip,
        Columns,
        Bars,
        Default,
      ]) => {
        parser.parse();
        let map;
        const dojo = window.dojo;

        let selectedReportType = $("#reportTypeSelect").val();
        let currentStateScore = {};
        // console.log('selectedReportType-------->', selectedReportType)
        let stateFeatures, districtFeatures;
        let symbol, legend;

        let colorsOfStateLayer = new GraphicsLayer();
        var polygraphlayer = new GraphicsLayer();
        var maskcirclelayer = new GraphicsLayer();
        var stdtbk_graphlyr = new GraphicsLayer();

        var renderer = new UniqueValueRenderer(null, "STCODE11");
        var distRenderer = new UniqueValueRenderer(null, "dtcode11");

        var sfs_stdtbk = new SimpleFillSymbol(
          SimpleFillSymbol.STYLE_NULL,
          new SimpleLineSymbol(
            SimpleLineSymbol.STYLE_SOLID,
            new Color([0, 0, 0]),
            2
          ),
          new Color([0, 206, 209, 0.25])
        );
        var maskcircleSymb = new SimpleFillSymbol(
          SimpleFillSymbol.STYLE_SOLID,
          new SimpleLineSymbol(
            SimpleLineSymbol.STYLE_SHORTDASHDOTDOT,
            new Color([105, 105, 105]),
            2
          ),
          new Color([255, 255, 255, 1])
        );
        var maskcircle = new Circle({
          center: [80, 19],
          geodesic: true,
          radius: 3000,
          radiusUnit: units.MILES,
        });

        // console.log('selectedReport------->', _.get(window, 'selectedReport'))


        map = new Map("mapEle", {
          logo: false,
          showAttribution: false,
          basemap: "gray-vector",
          // layers: [layer]
          center: [80, 19],
          zoom: 3,
          // container:mapElement
        });

        var home = new HomeButton({
          map: map
        }, "HomeButton");
        home.startup();

        home.on("home", function () {
          // backtoextent();
          document.getElementById("enableDistrictView").checked = true;

          districtFeatures = ''
          renderInitialStateLavelReport()
          document.getElementById("mapTypeRadioBtn").style.display = "none";
        });

        const stateBoundaryLayer = new ArcGISDynamicMapServiceLayer(
          `https://mapservice.gov.in/gismapservice/rest/services/BharatMapService/State_Boundary/MapServer?Token=${process.env.NODE_ENV === "development"
            ? "YHQcN7XVAL9fynPdC6hti7cmsMdG_1AazAe-Qy5VLq0ksWIU-YePHI0LMuzxbkfY"
            : "KtUZNiOhk03GUl3ZVKX_zqodSIlNlnYa2_XK0tfX2la-TdcP3-m45Bszz7y1b71ihzFy6vXPX0mjmD_PJs90Gg.."
          }`
        );
        map.addLayer(stateBoundaryLayer);

        // map.addLayer(new ArcGISDynamicMapServiceLayer('https://mapservice.gov.in/gismapservice/rest/services/BharatMapService/State_Boundary/MapServer?Token=Uxgz3q57UIip4DOsyKGhqqQjsLJTVgG_DPtrRf64jOS5rBe_l0ucyulCYGB9c4ZK'));
        // map.setExtent(new Extent(66.62, 5.23, 98.87, 38.59, new SpatialReference({ wkid: 4326 })),true)

        const renderInitialStateLavelReport = () => {
          document.getElementById("mapLoader").style.display = "block";

          currentStateScore = stateWiseDataFilterByReportType(
            selectedReportType,
            { code: "StateCode", name: "StateName" }
          );
          if (map.layer) {
            map.layer.clear();
          }

          // Added StateWise Color
          var query = new Query();
          query.where = "1=1";
          query.outFields = ["STNAME,STCODE11", "State_LGD"];
          query.returnGeometry = true;

          var queryTask = new QueryTask(layerurl + "/" + 0 + token);

          queryTask.execute(query, fillColorState);

          // render template
          renderReportTable(currentStateScore, selectedReportType, 'state');
        };

        // Add District Layer
        const addedDistrictLayer = (stateFeatures) => {
          const stateCode = _.get(stateFeatures, "0.attributes.STCODE11");
          currentStateScore = stateWiseDataFilterByReportType(
            selectedReportType,
            { code: "StateCode", name: "StateName" }
          );

          if (map.layer) {
            map.layer.clear();
          }



          function createSymbol(color) {
            return new SimpleLineSymbol(
              SimpleLineSymbol.STYLE_SOLID,
              new Color(color),
              10
            );
          }


          // Added StateWise Color
          var query = new Query();
          query.where = "State_LGD=" + stateCode;
          // query.where = "State_LGD='" + stateCode + "'";
          query.outFields = [
            "dtname",
            "dtcode11",
            "STNAME",
            "STCODE11",
            "State_LGD",
          ];
          query.returnGeometry = true;

          var queryTask = new QueryTask(
            distLayerUrl + "/" + 0 + distLayerToken
          );
          // queryTask.execute(query,  dojo.partial(dtbkfun_ext));
          queryTask.execute(query, addedDistrictBoundry);
        };


        const renderParliamentConstituencyReport = (stateFeatures) => {
          document.getElementById("mapLoader").style.display = "block";
          const stateCode = _.get(stateFeatures, "0.attributes.STCODE11");
          map.graphics.clear();

          // // Added PC  layer
          var query = new Query();
          query.where = "1=1";
          query.where = `ST_CODE='${stateCode}'`;
          // query.where = "PC_ID=" + 1027;

          query.outFields = ["*"];
          query.returnGeometry = true;
          var queryTask = new QueryTask(PCandACLayerUrl + "/" +1+ PCandACLayerToken);
          queryTask.execute(query);
          queryTask.execute(query, ParliamentBoundry);
 
      
          // document.getElementById("mapLoader").style.display = "none";

        };

        

        const renderAssemblyConstituencyReport = () => {
          document.getElementById("mapLoader").style.display = "block";
          const stateCode = _.get(stateFeatures, "0.attributes.STCODE11");
          map.graphics.clear();

          // // Added PC  layer
          var query = new Query();
          query.where = "1=1";
          query.where = `ST_CODE='${stateCode}'`;

          query.outFields = ["*"];
          query.returnGeometry = true;
          var queryTask = new QueryTask(PCandACLayerUrl + "/" +2+ PCandACLayerToken);
          queryTask.execute(query);
          queryTask.execute(query, AssemblyBoundry);

        //  const PCACoundaryLayer = new ArcGISDynamicMapServiceLayer(
        //   `${PCandACLayerUrl}${PCandACLayerToken}`
        // );
        // map.addLayer(PCACoundaryLayer);
        
     

        };

        function ParliamentBoundry(fs) {
          // console.log('fs------------->>',fs)
          const stateCode = Number(_.get(fs, "features.0.attributes.State_LGD"));
          // const fSfeatures = _.get(fs,'operationalLayers.0.featureCollection.layers.0.featureSet.features')
          // console.log('FER------------->>',fSfeatures)

          map.setExtent(stateFeatures[0].geometry.getExtent(), true);
          const parlConstData = _.filter(
            stateWiseDataFilterByReportType(selectedReportType, {
              code: "ParlConstCode",
              name: "ParlConstName",
            }),
            (fl) => Number(fl.StateCode) === stateCode
          );

          // console.log("parlConstData----->", { stateCode, parlConstData });
          // renderReportTable(districtData, selectedReportType, 'district');
          // districtFeatures = fs.features
          var infoTemplate = new InfoTemplate();

          dojo.forEach(fs.features, function (feature) {
            // console.log('feature------->', feature)
            let graphic = feature;
            let stateColorSymbol;
            const originScore = _.find(
              parlConstData,
              (f) =>{
                const pcName = _.get(f,'name','').replace(/\s/g,'').toLowerCase()
                const mapPCname = _.get(feature, "attributes.pc_name").replace(/\s/g,'').toLowerCase()
                // console.log({pcName, mapPCname})
               return( Number(_.get(f, "ParlConstCode", 0)) === Number(_.get(feature, "attributes.pc_id"))  || mapPCname.indexOf(pcName) !== - 1)
            });
            // console.log('originScore-------->>',originScore)
            graphic.attributes.demand = _.get(originScore, 'demand', 0)
            graphic.attributes.sanctioned = _.get(originScore, 'sanctioned', 0)
            graphic.attributes.committed = _.get(originScore, 'committed', 0)
            graphic.attributes.completed = _.get(originScore, 'completed', 0)
            graphic.attributes.grounded = _.get(originScore, 'grounded', 0)
            graphic.attributes.overall = _.get(originScore, 'overall', 0)
            graphic.attributes.released = _.get(originScore, 'released', 0)
            graphic.attributes.stateGrant = _.get(originScore, 'stateGrant', 0)
            graphic.attributes.expenditure = _.get(originScore, 'expenditure', 0)

            // console.log('parlConstData------------->',parlConstData)

            stateColorSymbol = new SimpleFillSymbol(
              SimpleFillSymbol.STYLE_SOLID,
              new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new dojo.Color("#002b49"),
                0.2
              ),
              new dojo.Color(_.get(originScore, "color"))
            );

            // Add Info template
            // console.log('reportType----->>?', reportType)
            if (selectedReportType === "demandSaturation") {
              var content =
                "<b>Demand: </b>${demand:NumberFormat}<br/>" +
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>";
              infoTemplate.setTitle("${pc_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "constructionVSsanctioned" || selectedReportType === "completionVSsanctioned") {
              var content =
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>" +
                "<b>Houses Grounded *: </b>${grounded:NumberFormat}<br/>" +
                "<b>Houses Completed *: </b>${completed:NumberFormat}<br/>";
              infoTemplate.setTitle("${pc_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "investmentMobilisation") {
              var content =
                "<b>Total Investment: </b>${overall:NumberFormat}<br/>" +
                "<b>Investment By Districts: </b>${(overall - committed):NumberFormat}<br/>";
              infoTemplate.setTitle("${pc_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "releaseCentralAssistance") {
              var content =
                "<b>Sanctioned: </b>${committed:NumberFormat}<br/>" +
                "<b>Released: </b>${released:NumberFormat}<br/>";
              infoTemplate.setTitle("${pc_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "fundUtilisation") {
              var content =
                "<b>Released: </b>${released:NumberFormat}<br/>" +
                "<b>Expenditure: </b>${expenditure:NumberFormat}<br/>";
              infoTemplate.setTitle("${pc_name}");
              infoTemplate.setContent(content);
            } else {
              var content =
                "<b>Info data not avl.</b>" +
                infoTemplate.setTitle("${pc_name}");
              infoTemplate.setContent(content);
            }

            // console.log('graphi---------??', graphic)
            graphic.setInfoTemplate(infoTemplate);
            graphic.setSymbol(stateColorSymbol);
            // graphic.setSymbol(sfs_stdtbk);
            // Add graphic to the map graphics layer.
            map.graphics.add(graphic);
         
          });
          // render template
          renderReportTable(parlConstData, selectedReportType, 'parliamentConstituency');
          document.getElementById("mapLoader").style.display = "none";
        }


        function AssemblyBoundry(fs) {
          // console.log('fs----->>', fs)
          const stateCode = Number(_.get(fs, "features.0.attributes.State_LGD"));

          map.setExtent(stateFeatures[0].geometry.getExtent(), true);
          const assamblyData = _.filter(
            stateWiseDataFilterByReportType(selectedReportType, {
              code: "AssemConstCode",
              name: "AssemConstName",
            }),
            (fl) => Number(fl.StateCode) === stateCode
          );

          renderReportTable(assamblyData, selectedReportType, 'district');
          // districtFeatures = fs.features
          var infoTemplate = new InfoTemplate();

          dojo.forEach(fs.features, function (feature) {
            // console.log('feature------->', feature)
            let graphic = feature;
            let stateColorSymbol;
            const originScore = _.find(
              assamblyData,
              (f) =>{
                const acName = _.get(f,'name','').replace(/\s/g,'').toLowerCase()
                const mapACname = _.get(feature, "attributes.ac_name").replace(/\s/g,'').toLowerCase()
               return( Number(_.get(f, "AssemConstCode", 0)) === Number(_.get(feature, "attributes.AC_ID"))  || mapACname.indexOf(acName) !== - 1)
            });
            // console.log('originScore-------->>',originScore)
            graphic.attributes.demand = _.get(originScore, 'demand', 0)
            graphic.attributes.sanctioned = _.get(originScore, 'sanctioned', 0)
            graphic.attributes.committed = _.get(originScore, 'committed', 0)
            graphic.attributes.completed = _.get(originScore, 'completed', 0)
            graphic.attributes.grounded = _.get(originScore, 'grounded', 0)
            graphic.attributes.overall = _.get(originScore, 'overall', 0)
            graphic.attributes.released = _.get(originScore, 'released', 0)
            graphic.attributes.stateGrant = _.get(originScore, 'stateGrant', 0)
            graphic.attributes.expenditure = _.get(originScore, 'expenditure', 0)


            stateColorSymbol = new SimpleFillSymbol(
              SimpleFillSymbol.STYLE_SOLID,
              new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new dojo.Color("#002b49"),
                0.2
              ),
              new dojo.Color(_.get(originScore, "color"))
            );

            // Add Info template
            // console.log('reportType----->>?', reportType)
            if (selectedReportType === "demandSaturation") {
              var content =
                "<b>Demand: </b>${demand:NumberFormat}<br/>" +
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>";
              infoTemplate.setTitle("${ac_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "constructionVSsanctioned" || selectedReportType === "completionVSsanctioned") {
              var content =
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>" +
                "<b>Houses Grounded *: </b>${grounded:NumberFormat}<br/>" +
                "<b>Houses Completed *: </b>${completed:NumberFormat}<br/>";
              infoTemplate.setTitle("${ac_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "investmentMobilisation") {
              var content =
                "<b>Total Investment: </b>${overall:NumberFormat}<br/>" +
                "<b>Investment By Districts: </b>${(overall - committed):NumberFormat}<br/>";
              infoTemplate.setTitle("${ac_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "releaseCentralAssistance") {
              var content =
                "<b>Sanctioned: </b>${committed:NumberFormat}<br/>" +
                "<b>Released: </b>${released:NumberFormat}<br/>";
              infoTemplate.setTitle("${ac_name}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "fundUtilisation") {
              var content =
                "<b>Released: </b>${released:NumberFormat}<br/>" +
                "<b>Expenditure: </b>${expenditure:NumberFormat}<br/>";
              infoTemplate.setTitle("${ac_name}");
              infoTemplate.setContent(content);
            } else {
              var content =
                "<b>Info data not avl.</b>" +
                infoTemplate.setTitle("${ac_name}");
              infoTemplate.setContent(content);
            }

            // console.log('graphi---------??', graphic)
            graphic.setInfoTemplate(infoTemplate);
            graphic.setSymbol(stateColorSymbol);
            // graphic.setSymbol(sfs_stdtbk);
            // Add graphic to the map graphics layer.
            map.graphics.add(graphic);
          });
          // render template
          renderReportTable(assamblyData, selectedReportType, 'assemblyConstituency');
          document.getElementById("mapLoader").style.display = "none";
        }

        // Change Report Type
        $(document).on("change", "#reportTypeSelect", function (evt) {
          selectedReportType = evt.target.value;
          document.getElementById("mapLoader").style.display = "block";
          const mapType = $("input[type=radio][name=mapType]:checked").val()
          // console.log('*-*-*-*->>',{selectedReportType,mapType},stateFeatures && districtFeatures)

          if (stateFeatures && districtFeatures && mapType === 'enableDistrictView') {
            addedDistrictLayer(stateFeatures)
          }else if (mapType === 'enableConstituencyView') {
            renderParliamentConstituencyReport(stateFeatures)
          }else if (mapType === 'enableAssemblyView') {
            renderAssemblyConstituencyReport(stateFeatures)
          } else {
            renderInitialStateLavelReport();
          }
        });

        // Change table View
        $(document).on("change", "#showTable", function (evt) {
          const showTableView = $(this).is(":checked")
          if (showTableView) {
            $('#mapWrapper').removeClass()
            $('#mapWrapper').addClass('col-lg-8 col-md-8 col-sm-7')
            $('#tableWrapper').css('display', 'block')
          } else {
            $('#mapWrapper').removeClass()
            $('#mapWrapper').addClass('col-lg-12 col-md-12 col-sm-12')
            $('#tableWrapper').css('display', 'none')
          }
        });


        // Enable/Disable Constituency View
        $(document).on("change", "input[type=radio][name=mapType]", function (evt) {
          // const enableConstituencyView = $(this).is(":checked")
           const mapType = this.value
          //  console.log('mapType--------->', mapType)
          if (mapType === 'enableDistrictView') {
            addedDistrictLayer(stateFeatures)
          }else if (mapType === 'enableConstituencyView') {
            renderParliamentConstituencyReport(stateFeatures)
          }else if (mapType === 'enableAssemblyView') {
            renderAssemblyConstituencyReport(stateFeatures)
          }else {
            renderInitialStateLavelReport()
          }
        });
        

        // click on map function----------------------------------------------------------------------------
        dojo.connect(map, "onClick", executeQueryTask);

        // Query--------------------------------------------------------------

        var query = new Query();
        query.where = "1=1";
        query.outFields = ["STNAME,STCODE11", "State_LGD"];
        query.returnGeometry = true;


        function executeQueryTask(evt) {
          if(!_.get(evt,'graphic')){
            return
          }
          // console.log('evt----->>', evt)
          document.getElementById("mapLoader").style.display = "block";

          query.geometry = evt.mapPoint;
          var queryTask = new QueryTask(layerurl + "/" + 0 + token);
          queryTask.execute(query, showResults);
          // console.log('queryTask----->>', queryTask)
        }

        function showResults(featureSet) {
          //remove all graphics on the maps graphics layer
          map.graphics.clear();

          var features = featureSet.features;
          // console.log("features------->", features);
          stateFeatures = features;
          addedDistrictLayer(features);
        }

        // End click on map function----------------------------------------------------------------------------

        // All State Coloring Function*******************************************************************

        // let stateColorSymbol = new SimpleFillSymbol();
        // stateColorSymbol.setColor(new Color([150,150,150,0.5]));
        // let colorRender = new ClassBreaksRenderer(stateColorSymbol,'State_LGD')

        map.on("load", function () {
          renderInitialStateLavelReport(); // StateWise fill Color , Table Generate and create legend
        });

        // Fill Color StateWise
        function fillColorState(featureSet) {
          // map.graphics.clear();
          var featuresOfStates = featureSet.features;
          // console.log('features------->', features)
          //QueryTask returns a featureSet.  Loop through features in the featureSet and add them to the map.

          var infoTemplate = new InfoTemplate();

          dojo.forEach(featuresOfStates, function (feature) {
            let graphic = feature;
            let stateColorSymbol;
            // console.log('selectedReportType------->',selectedReportType)

            const originScore = _.find(
              currentStateScore,
              (f) =>
                Number(_.get(f, "value", 0)) ===
                _.get(feature, "attributes.State_LGD")
            );

            // console.log('originScore------->', originScore)
            graphic.attributes.demand = _.get(originScore, 'demand', 0)
            graphic.attributes.sanctioned = _.get(originScore, 'sanctioned', 0)
            graphic.attributes.committed = _.get(originScore, 'committed', 0)
            graphic.attributes.completed = _.get(originScore, 'completed', 0)
            graphic.attributes.grounded = _.get(originScore, 'grounded', 0)
            graphic.attributes.overall = _.get(originScore, 'overall', 0)
            graphic.attributes.released = _.get(originScore, 'released', 0)
            graphic.attributes.stateGrant = _.get(originScore, 'stateGrant', 0)
            graphic.attributes.expenditure = _.get(originScore, 'expenditure', 0)

            // Add Color
            stateColorSymbol = new SimpleFillSymbol(
              SimpleFillSymbol.STYLE_SOLID,
              new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new dojo.Color("#002b49"),
                0.2
              ),
              new dojo.Color(_.get(originScore, "color"))
            );
            // Add Info template
            // console.log('reportType----->>?', reportType)
            if (selectedReportType === "demandSaturation") {
              var content =
                "<b>Demand: </b>${demand:NumberFormat}<br/>" +
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>";
              infoTemplate.setTitle("${STNAME}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "constructionVSsanctioned" || selectedReportType === "completionVSsanctioned") {
              var content =
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>" +
                "<b>Houses Grounded *: </b>${grounded:NumberFormat}<br/>" +
                "<b>Houses Completed *: </b>${completed:NumberFormat}<br/>";
              infoTemplate.setTitle("${STNAME}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "investmentMobilisation") {
              var content =
                "<b>Total Investment: </b>${overall:NumberFormat}<br/>" +
                "<b>Investment By State/UT: </b>${(overall - committed):NumberFormat}<br/>";
              infoTemplate.setTitle("${STNAME}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "releaseCentralAssistance") {
              var content =
                "<b>Sanctioned: </b>${committed:NumberFormat}<br/>" +
                "<b>Released: </b>${released:NumberFormat}<br/>";
              infoTemplate.setTitle("${STNAME}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "fundUtilisation") {
              var content =
                "<b>Released: </b>${released:NumberFormat}<br/>" +
                "<b>Expenditure: </b>${expenditure:NumberFormat}<br/>";
              infoTemplate.setTitle("${STNAME}");
              infoTemplate.setContent(content);
            } else {
              var content =
                "<b>Info data not avl.</b>" +
                infoTemplate.setTitle("${STNAME}");
              infoTemplate.setContent(content);
            }


            graphic.setInfoTemplate(infoTemplate);
            graphic.setSymbol(stateColorSymbol);

            //Add graphic to the map graphics layer.
            map.graphics.add(graphic);
            document.getElementById("mapLoader").style.display = "none";
          });
          dojo.connect(map.graphics, "onMouseMove", function (evt) {
            var g = evt.graphic;
            map.infoWindow.setContent(g.getContent());
            map.infoWindow.setTitle(g.getTitle());
            map.infoWindow.show(evt.screenPoint, map.getInfoWindowAnchor(evt.screenPoint));
          });
          dojo.connect(map.graphics, "onMouseOut", function () { map.infoWindow.hide(); });

        }

        function addedDistrictBoundry(fs) {
          // console.log('fs=--------->',fs)
          document.getElementById("mapTypeRadioBtn").style.display = "flex";

          const stateCode = Number(_.get(fs, "features.0.attributes.stcode11"));

          map.setExtent(stateFeatures[0].geometry.getExtent(), true);
          const districtData = _.filter(
            stateWiseDataFilterByReportType(selectedReportType, {
              code: "DistrictCode",
              name: "DistrictName",
            }),
            (fl) => Number(fl.StateCode) === stateCode
          );

          // console.log("districtData----->", { stateCode, districtData });
          renderReportTable(districtData, selectedReportType, 'district');
          districtFeatures = fs.features
          var infoTemplate = new InfoTemplate();

          dojo.forEach(fs.features, function (feature) {
            // console.log('feature------->', feature)
            let graphic = feature;
            let stateColorSymbol;
            const originScore = _.find(
              districtData,
              (f) =>{
                const distName = _.get(f,'name','').replace(/\s/g,'').toLowerCase()
                const mapDTname = _.get(feature, "attributes.dtname").replace(/\s/g,'').toLowerCase()
                // console.log('-->',distName,mapDTname)
                return(
                Number(_.get(f, "DistrictCode", 0)) === Number(_.get(feature, "attributes.dtcode11")) || distName.indexOf(mapDTname) !== - 1
                )
            });
            // console.log('originScore----->>',originScore)
            graphic.attributes.demand = _.get(originScore, 'demand', 0)
            graphic.attributes.sanctioned = _.get(originScore, 'sanctioned', 0)
            graphic.attributes.committed = _.get(originScore, 'committed', 0)
            graphic.attributes.completed = _.get(originScore, 'completed', 0)
            graphic.attributes.grounded = _.get(originScore, 'grounded', 0)
            graphic.attributes.overall = _.get(originScore, 'overall', 0)
            graphic.attributes.released = _.get(originScore, 'released', 0)
            graphic.attributes.stateGrant = _.get(originScore, 'stateGrant', 0)
            graphic.attributes.expenditure = _.get(originScore, 'expenditure', 0)

            // console.log('districtData------------->',districtData)

            stateColorSymbol = new SimpleFillSymbol(
              SimpleFillSymbol.STYLE_SOLID,
              new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new dojo.Color("#002b49"),
                0.2
              ),
              new dojo.Color(_.get(originScore, "color"))
            );

            // Add Info template
            // console.log('reportType----->>?', reportType)
            if (selectedReportType === "demandSaturation") {
              var content =
                "<b>Demand: </b>${demand:NumberFormat}<br/>" +
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>";
              infoTemplate.setTitle("${dtname}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "constructionVSsanctioned" || selectedReportType === "completionVSsanctioned") {
              var content =
                "<b>Houses Sanctioned: </b>${sanctioned:NumberFormat}<br/>" +
                "<b>Houses Grounded *: </b>${grounded:NumberFormat}<br/>" +
                "<b>Houses Completed *: </b>${completed:NumberFormat}<br/>";
              infoTemplate.setTitle("${dtname}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "investmentMobilisation") {
              var content =
                "<b>Total Investment: </b>${overall:NumberFormat}<br/>" +
                "<b>Investment By Districts: </b>${(overall - committed):NumberFormat}<br/>";
              infoTemplate.setTitle("${dtname}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "releaseCentralAssistance") {
              var content =
                "<b>Sanctioned: </b>${committed:NumberFormat}<br/>" +
                "<b>Released: </b>${released:NumberFormat}<br/>";
              infoTemplate.setTitle("${dtname}");
              infoTemplate.setContent(content);
            } else if (selectedReportType === "fundUtilisation") {
              var content =
                "<b>Released: </b>${released:NumberFormat}<br/>" +
                "<b>Expenditure: </b>${expenditure:NumberFormat}<br/>";
              infoTemplate.setTitle("${dtname}");
              infoTemplate.setContent(content);
            } else {
              var content =
                "<b>Info data not avl.</b>" +
                infoTemplate.setTitle("${dtname}");
              infoTemplate.setContent(content);
            }


            graphic.setInfoTemplate(infoTemplate);
            graphic.setSymbol(stateColorSymbol);
            // graphic.setSymbol(sfs_stdtbk);
            //Add graphic to the map graphics layer.
            map.graphics.add(graphic);
          });
          document.getElementById("mapLoader").style.display = "none";
        }

        // End All State Coloring Function*******************************************************************
      }
    );
    return () => {
      if (!!map) {
        map.destroy();
        map = null;
      }
    };
  });
  return (
    <div style={{ height: 800, width: "100%" }} id="mapEle" ref={mapElement}>
      <div id="widgetContainer">
        <div
          id="HomeButton"
          style={{
            position: "absolute",
          }}
        />
        <SutterLoader style={{ display: 'none' }} id='mapLoader' isLoaded={false} />
      </div>
    </div>
  );
};

export default Maps;
